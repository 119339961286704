import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"


class Index extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Ошибка 404 - Исполиния</title>
          <meta name="description"
                content="Ошибка 404. Данной страницы не существует." />

          <meta property="og:title" content="Главная" />
          <meta property="og:description"
                content="Ошибка 404. Данной страницы не сущестует." />

        </Helmet>
        <Header />
        <main id="notfound">
          <Container fluid={true}>
          <h1>404</h1>
            <h2>К сожалению, вы попали на страницу, которой не существует</h2>
            <div className="buttons">
              <Link
                to="/">
                <button className="col-md">На главную</button>
              </Link>
            </div>
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

export default Index